<template>

	  <div>
	  	
	  	<svg :width="boxwidth" :height="boxheight" version="1.1" xmlns="http://www.w3.org/2000/svg">
		  

	  	  <template  v-for="( track, id ) in tracks">

	  	  <rect :key="'rect2_'+id" :fill=" id%2==0 ? '#f3f4f5': '#ffffff'" x="0" :y="id*50" height="50" :width="boxwidth" />  

		  <rect  @click="selectbar(track)" :key="'rect_'+id" :x="getX(track.trackstart)" :y="getY(id)" :width="(track.endday - track.startday)*pixel" height="30" stroke="#2e477b" fill="#2e477b" stroke-width="1" />
		
	      <g :key="id+'_'+mid" v-for="( milestone, mid ) in track.milestones" :transform="getXY( milestone, id ) + ' rotate(-45)'">
		    <rect x="0" y="0" width="9" height="9" rx="1"
		          :fill="milestone.iscritical ? '#d24642': 'gray'" :stroke="milestone.iscritical ? '#d24642': 'gray'" stroke-width="1" />
		  </g>

		  <text font-family="Oxygen" font-size="15px" :key="'text_'+id" x="40" :y="getY(id)+20" text-anchor="left">{{ track.label }}</text>
		  

		  </template>

		  <path v-if="criticalpathready" :d="rendercriticalpath()" fill="none" stroke="#d24642" stroke-width="1" style="opacity: .8;"/>


		  <text :key="id" v-for="( y, id ) in yaxis" font-family="Oxygen" font-size="12px" :x="20 + (100 * id)" y="400" text-anchor="left"> {{y}} </text>
		</svg>

	  </div>


</template>

<style type="text/css">
	

</style>


<script type="text/javascript">
	
</script>

<script>

	import Userapi from '@/services/api/users'

	export default{
		name:'gantt2',
		data(){
			return{
				tracks:[],
				mindate:null,
				maxdate:null,
				chartdays:0,
				startY:60,
				barheight:50,
				boxwidth:500,
				boxheight:450,
				pixel:0.0,
				pl:20,
				pt:20,
				pb:20,
				criticalpath:[],
				sortedcriticalpath:[],
				criticalpathready:false,
				yaxis:[]
			}
		},
		props:{
			getalltracks:Promise,
		},

		watch:{
			
		},
		mounted(){

			$('#preloader').addClass('hide')

			let self = this

				//alert('')
			Userapi.Tracks( this.cockpitid, this.token, "open" ).then( function(response){

				self.tracks = response.data.tracks 

				for( let i in self.tracks ){

					let startdate = new Date( self.tracks[i].trackstart )  
					let enddate = new Date( self.tracks[i].trackend ) 


					if( self.mindate ){

						if( startdate < self.mindate ){
							self.mindate = startdate
						}

					}else{
						self.mindate = startdate
					}

					if( self.maxdate ){

						if( enddate > self.maxdate ){
							self.maxdate = enddate
						}

					}else{
						self.maxdate = enddate
					}

					//let timebetween = self.maxdate - self.mindate
					self.chartdays = ( self.maxdate - self.mindate ) / (1000 * 3600 * 24)

				 	self.pixel = ( self.boxwidth - 40 ) / self.chartdays

				}

				for( let i in self.tracks ){

					let startdate = new Date( self.tracks[i].trackstart )  
					let enddate = new Date( self.tracks[i].trackend ) 

					self.tracks[i].startday = ( startdate - self.mindate ) / (1000 * 3600 * 24)
					self.tracks[i].endday = ( enddate - self.mindate ) / (1000 * 3600 * 24)

					if( self.tracks[i].milestones ){
						for( let o in self.tracks[i].milestones ){

							if( self.tracks[i].milestones[o].iscritical == true ){

								self.criticalpath.push( { date:self.tracks[i].milestones[o].eventdate, index:i }  )

							}

							
						}
					}
					
				}

				/*self.yaxis.push( self.simpleDate(self.mindate) )
				


				let p = parseInt(self.chartdays / 5) 

				

				now.setDate( now.getDate() + ( 1 * p ) )
				

				now.setDate( now.getDate() + ( 1 * p ) )
				self.yaxis.push( self.simpleDate(now)  )

				now.setDate( now.getDate() + ( 1 * p ) )
				self.yaxis.push( self.simpleDate(now)  )

				//now.setDate( now.getDate() + ( 1 * p ) )
				//s///elf.yaxis.push( self.simpleDate(now)  )

				*/

				/*self.yaxis.push( self.mindate )
				self.yaxis.push( date.setDate(date.getDate() + ( 1 * p ) ))
				self.yaxis.push( date.setDate(date.getDate() + ( 2 * p ) ))
				self.yaxis.push( date.setDate(date.getDate() + ( 3 * p ) ))
				self.yaxis.push( self.maxdate )*/

				let now = self.mindate
				self.yaxis.push( self.simpleDate(now)  )
				self.yaxis.push( ""  )
				self.yaxis.push( ""  )
				self.yaxis.push( ""  )
				self.yaxis.push( self.simpleDate(self.maxdate) )

				self.sortedcriticalpath = self.criticalpath.sort ( function (a, b){
					return new Date(a.date) - new Date(b.date);
				});

				self.criticalpath = self.sortedcriticalpath

				self.criticalpathready = true

			})			


		},
		methods:{

			simpleDate( date ){
				let dte = date.toDateString()

				let rawdate = dte.split(" ")

				return rawdate[1] + ' ' + rawdate[3]
			},

			rendercriticalpath(){

				let render = null

				if( this.criticalpath && this.criticalpath.length > 1 ){

					let lastindex = 0
					let lastX = 0

					for( let i in this.criticalpath ){

						let x = this.getX( this.criticalpath[i].date )
						let y = this.getY( this.criticalpath[i].index )

						x = x - 12
						y = y + 30


						if( render && this.criticalpath[i].index != lastindex ){
							render.push( 'L'+lastX+','+y )
						}

						if( render ){
							render.push( 'L'+x+','+y )
						}else{
							render = []
							render.push( 'M'+x+','+y )
						}

						lastX = x 
						lastindex = this.criticalpath[i].index
					}
				}

				if( render ){
					return render.join(' ')
				}

				
			},

			getXY( milestone, index ){

				let daysdiff = ( new Date( milestone.eventdate )  - this.mindate ) / (1000 * 3600 * 24)
				let x = parseInt( daysdiff * this.pixel ) 
				let y = this.getY(index) + 30
				return 'translate(' + x + ' ' + y + ')'
			},

			selectbar( track ){

				console.log( track._id )

			},

			getY( index ){

				return ( this.barheight * index) + 10
			},

			getX( xdate ){			

				return parseInt( ( new Date(xdate) - this.mindate ) / (1000 * 3600 * 24) * this.pixel) + this.pl

			}

		},
		computed:{


			cockpitid(){
        		return this.$route.query.boardid
     		},

			orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },	

			token(){
				return this.$store.getters.token
			},

      		cockpit(){
				return this.$store.getters.GetCockpit
			},

		}
	}
	//  
</script>